import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InspectionEvaluationsService } from '../../services/inspection-evaluations.service';
import { FiltersService } from 'src/app/core/services/filters/filters.service';
import { ReplaySubject } from 'rxjs';
import { FilterUtilService } from 'src/app/core/services/uitls/filter-utilizer/filter-util.service';
import { Title } from '@angular/platform-browser';
import { SelectUtil } from 'src/app/core/services/uitls/selectUtil';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';

@Component({
  selector: 'app-evaluation-list',
  templateUrl: './evaluation-list.component.html',
  styleUrl: './evaluation-list.component.scss'
})
export class EvaluationListComponent implements OnInit {

  inspectionEvaluations = [];
  config: { id: string; itemsPerPage: number; currentPage: any; totalItems: any; pagesCount: number };
  pageTitle: string = 'التقييم التلقائى للفحوصات';
  filterPage: string = 'EVALUATION';
  filterData;
  filterDataInit = {};
  filterParamsUrl = {};
  savedFilters: any = [];
  spinnerKey: string = 'spinner';
  spinner: boolean = true;
  disabledDelete: boolean = true;
  deletedEvaluationID: any[];
  deletedEvaluationIndex: any[];
  selectedRowsIndex: any[] = [];
  selectedRowsPK: any[] = [];
  modelImg: boolean;
  confirmMsg: string;
  @ViewChildren('checkBoxList') checkBoxList: ElementRef<HTMLInputElement>[];
  @ViewChild('selectAllElm') selectAllElm: ElementRef<HTMLInputElement>;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  

  constructor(private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private dialog: MatDialog,
    private inspectionEvaluationsService: InspectionEvaluationsService,
    private filter: FiltersService,
    private filterUtil: FilterUtilService
  ) {
    this.config = {
      id: 'myPagination',
      itemsPerPage: 20,
      currentPage: 1,
      totalItems: 0,
      pagesCount: 0
    };
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.filterUtil.clearfilterParams(this.filterParamsUrl);
      this.filterUtil.clearfilterParams(this.filterDataInit);
      if (Object.keys(params).length > 0) {
        this.filterParamsUrl = { ...params };
        this.filterDataInit = { ...params };
      }
    });
    this.getEvaluationsFilter();
    this.titleService.setTitle(' التقييم التلقائى | عاين');
    this.filterUtil.getSavedFilters(this, this.filterPage);
    this.filter.changeSelectedFilter(this.filterDataInit);
    this.filterUtil.applySelectedFilter(
      this,
      this.getInspectionEvaluations,
      this.spinnerKey
    );
  }

  getInspectionEvaluations() {
    let params = { ...this.filterParamsUrl};
    params["page"] = this.config.currentPage;
    params["page_size"] = this.config.itemsPerPage;
    
    this.inspectionEvaluationsService.getInspectionEvaluations(params).subscribe(
      data => {
        this.spinner = false;
        this.inspectionEvaluations = data.results;
        this.config['totalItems'] = data.count;

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: this.filterParamsUrl,
          queryParamsHandling: '',
        });
      }
    );
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.getInspectionEvaluations();
  }

  pageSizeChanged(event) {
    this.config.itemsPerPage = event;
    this.getInspectionEvaluations();
  }

  openEvaluation(param) {
    this.router.navigateByUrl(`/pages/projects/evaluation/${param}`);
  }

  getEvaluationsFilter() {
    let params = {
      filter_page: this.filterPage,
    };
    this.filter.getFiltersData(params).subscribe(
      (data) => {
        this.filterData = data;
      }
    );
  }

  filterFormValues(event) {
    this.spinner = true;
    this.filterParamsUrl = event.filterParamsUrl;
    this.config.currentPage = 1;
    this.getInspectionEvaluations();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  selectAll(event) {
    this.disabledDelete = true;
    SelectUtil.selectAll(event, this);
  }

  deleteSelectedRows() {
    this.deletedEvaluationID = this.selectedRowsPK;
    this.deletedEvaluationIndex = this.selectedRowsIndex;
    this.showDeleteConfirmationBox();
  }

  selectsSingleRow(event, rowPk, index) {
    this.disabledDelete = true;
    SelectUtil.selectsSingleRow(event, rowPk, index, this);
    if (this.selectedRowsPK.length > 0) {
      this.disabledDelete = false;
    }
  }

  showDeleteConfirmationBox() {
    this.deleteDialogRef = this.dialog.open(ConfirmationBoxComponent, {
      data: {
        id: this.deletedEvaluationID,
        index: this.deletedEvaluationIndex,
        message: 'هل أنت متأكد من حذف التقييمات؟',
      },
    });
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteEvaluation(result);
      }
    });
  }

  deleteEvaluation(event) {
    let index = event.index;
    let pk = event.id;
    this.inspectionEvaluationsService.deleteEvaluations(pk).subscribe(
      (data) => {
        for (let i = index.length - 1; i >= 0; i--) {
          let evaluationInd = index[i];
          this.inspectionEvaluations.splice(evaluationInd, 1);
        }
        this.selectedRowsPK = [];
        this.selectedRowsIndex = [];
      },
      (error) => {
        try {
          this.modelImg = false;
          this.confirmMsg = '  عذراً لا يمكن حذف التقييمات . ‎';
          this.showAlertBox();
          this.selectedRowsPK = [];
          this.selectedRowsIndex = [];
        } catch {}
      }
    );
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.confirmMsg,
        button: 'تم',
      },
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.filter.changeSelectedFilter({});
  }

}
