<div class="pagination-container">
  <div class="pageSize">
    <span>عرض</span>
    <ng-select [ngModel]="selectedItem" bindLabel="name" class="single-select" appendTo="body" [searchable]="false"
      [clearable]="false" (change)="pageSizeChanged($event)">
      @for (item of pageSizeValues; track item) {
      <ng-option [value]="item">
        {{ item }}
      </ng-option>
      }
    </ng-select>
    <span> من إجمالى {{totalCount}} </span>
  </div>
  <pagination-controls [id]="id" maxSize="5" directionLinks="true" [previousLabel]="''" [nextLabel]="''"
    (pageChange)="pageChanged($event)" class="my-pagination">
  </pagination-controls>
</div>
