export class tablesHeaderData {
  public static ORDER_HEADER_DATA = [
    {value: "reference_number", display_name: "رقم الطلب", sortable: true, visible: true},
    {value: "linked_order", display_name: "الطلبات المرتبطة", sortable: false, visible: true},
    {value: "order_client_object", display_name: "اسم العميل", sortable: false, visible: true},
    {value: "order_inspector", display_name: "اسم الفاحص", sortable: false, visible: true},
    {value: "order_type_object", display_name: "نوع الخدمة", sortable: false, visible: true},
    {value: "google_address_city", display_name: "مدينة الطلب", sortable: false, visible: true},
    {value: "created_at", display_name: "تاريخ الطلب", sortable: true, visible: true},
    {value: "order_date", display_name: "تاريخ الفحص", sortable: true, visible: true},  
    {value: "auto_assign_status", display_name: "حالة التعيين", sortable: false, visible: true},  
    {value: "order_status_object", display_name: "حالة الطلب", sortable: false, visible: true},
  ] 
}
