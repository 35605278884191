export class SelectUtil {
  public static selectAll(event, scope) {
    scope.selectedRowsIndex = [];
    scope.selectedRowsPK = [];
    scope.checkBoxList.forEach((item) => {
      item.nativeElement.checked = event.checked;
      if (event.checked) {
        let elementId = item.nativeElement.id;
        let index = +elementId.slice(0, elementId.indexOf('_'));
        let pk = +elementId.slice(elementId.indexOf('_') + 1);
        scope.selectedRowsIndex.push(index);
        scope.selectedRowsPK.push(pk);
        scope.disabledDelete = false;
      }
    });
  }

  public static selectsSingleRow(event, rowPk, index, scope) {
    if (event.target.checked) {
      scope.selectedRowsIndex.push(index);
      scope.selectedRowsPK.push(rowPk);
      if (scope.selectedRowsIndex.length === scope.checkBoxList.length) {
        scope.selectAllElm.nativeElement.checked = true;
      }
    } else {
      scope.selectAllElm.nativeElement.checked = false;
      if (scope.selectedRowsIndex.includes(index))
        scope.selectedRowsIndex.splice(scope.selectedRowsIndex.indexOf(index), 1);
      if (scope.selectedRowsPK.includes(rowPk))
        scope.selectedRowsPK.splice(scope.selectedRowsPK.indexOf(rowPk), 1);
    }
  }
}
