import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CouponsService } from 'src/app/features/copoun/services/coupons/coupons.service';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { BankCardsService } from '../../services/bank-cards/bank-cards.service';
import { AyenServices } from 'src/app/core/services/ayen-services/ayen-services.service';
import { OrderType } from 'src/app/models/enums/order-type.enum';
import { DynamicServices } from 'src/app/features/ayen-services/services/dynamic-services/dynamic-services.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-copoun-details',
  templateUrl: './copoun-details.component.html',
  styleUrls: ['./copoun-details.component.scss'],
})
export class CopounDetailsComponent implements OnInit {
  addCouponFormGroup: UntypedFormGroup;
  title: string;
  couponID;
  confirmMsg: string;
  Confirmation: boolean = false;
  alertmsg: boolean = false;
  message: string;
  productType;
  classifications;
  discountTypes;
  formatedStartDate;
  formatedEndDate;
  couponDetails;
  saveBtn;
  currentDate = new Date();
  productsLength;
  discountType = 0;
  showForm: boolean = false;
  @ViewChild('allSelected') allSelected;
  status: any;
  bankdata: any;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  successImg: boolean;
  orderType = OrderType;
  page: any = 1;
  page_count: any = 20;
  searchInput: string;
  dynamicServicesArr: any[] = [];
  dynamicServicesItems = new BehaviorSubject({});
  serviceData = this.dynamicServicesItems.asObservable();
  initDynamicServices: any[] = [];
  showDynamicServices: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private dialog: MatDialog,
    private bankService: BankCardsService,
    private ayenServices: AyenServices,
    private couponsService: CouponsService,
    private dynamicServices: DynamicServices
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.getCouponServices();
    this.getDiscountTyps();
    this.getCouponClassifications();
    this.getBanks();
    this.couponID = this.activatedRoute.snapshot.params.id;
    if (this.couponID > 0) {
      this.title = 'تعديل كود الخصم';
      this.saveBtn = 'تعديل';
      this.getCouponById(this.couponID);
      this.confirmMsg = 'تم تعديل كود الخصم بنجاح';
    } else {
      this.showForm = true;
      this.title = 'إضافة كود خصم جديد';
      this.saveBtn = 'إضافة';
      this.confirmMsg = 'تم إضافة كود الخصم بنجاح';
    }
  }

  createFormControls() {
    return this.addCouponFormGroup.controls;
  }

  createForm() {
    this.addCouponFormGroup = this.formBuilder.group({
      services: [null, Validators.required],
      code: [null, Validators.required],
      description: [null, [Validators.required, Validators.maxLength(30)]],
      classification: [null],
      status: [false],
      discount_type: [null, Validators.required],
      discount_value: [null, [Validators.required, Validators.min(0)]],
      maximum_discount_amount: [null],
      maximum_redeem_number: [null],
      bank: [null],
      involved_parties: ['BOTH'],
      startDate: [null],
      endDate: [null],
      start_date: [null],
      end_date: [null],
      dynamic_services: [null]
    });
  }

  getDynamicServices() {
    let params = {
      page: this.page,
      is_active: true,
    };
    if (this.searchInput && this.searchInput.length > 0) {
      params['name'] = this.searchInput;
    }
    this.dynamicServices.getServices(params).subscribe((data) => {
        this.page_count = data.pages_count;
        let results = data.results;
        let ids = new Set(this.dynamicServicesArr.map(d => d.id));
       this.dynamicServicesArr = [...this.dynamicServicesArr, ...results.filter(d => !ids.has(d.id))];
        this.dynamicServicesItems.next(this.dynamicServicesArr);    
    });
  }

  setDynamicServices(event) {
    this.initDynamicServices = event;
    this.addCouponFormGroup.controls.dynamic_services.patchValue([...event]);
  }

  onScrollOptions(event) {
    if (event && this.page < this.page_count) {
      this.page++;
      this.getDynamicServices();
    }
  }

  updateDynamicServices(event) {
    if (event.reset) {
      this.page = 1;
      this.searchInput = '';
    } else {
      this.page = event.page ? event.page : this.page;
      this.searchInput = event?.searchInput;
    }
    this.getDynamicServices();
  }

  getBanks() {
    this.bankService.getAllBanks().subscribe(
      (data) => {
        this.bankdata = data;
      },
      (error) => {
        this.handelBackEndError(error);
      }
    );
  }

  getCouponServices() {
    this.ayenServices.getAyenServices().subscribe(
      (data) => {
        this.productType = data;
        this.productsLength = data.length;
      },
      (error) => {
        this.handelBackEndError(error);
      }
    );
  }

  getCouponById(id) {
    this.couponsService.getCouponById(id).subscribe(
      (data) => {
        this.couponDetails = data;
        this.status = this.couponDetails.status;
        this.showForm = true;
        this.initDynamicServices = data?.dynamic_services;
        if (this.initDynamicServices.length > 0) {
          this.initDynamicServices.forEach(service_id => {
            this.dynamicServices.getServiceById(service_id).subscribe((data) => {
              const service = {
                id: data.id,
                name: data.name,
              }
              this.dynamicServicesArr = [...this.dynamicServicesArr, service];
              this.dynamicServicesItems.next(this.dynamicServicesArr);  
            });
          });
        }
        this.addCouponFormGroup.patchValue({
          services: this.couponDetails.services,
          code: this.couponDetails.code,
          description: this.couponDetails.description,
          classification: this.couponDetails.classification,
          status: this.couponDetails.status,
          discount_type: this.couponDetails.discount_type,
          discount_value: this.couponDetails.discount_value,
          maximum_discount_amount: this.couponDetails.maximum_discount_amount,
          maximum_redeem_number: this.couponDetails.maximum_redeem_number,
          bank: this.couponDetails.bank,
          involved_parties: this.couponDetails.involved_parties,
          startDate: this.couponDetails.start_date,
          endDate: this.couponDetails.end_date,
          dynamic_service: this.couponDetails.dynamic_services
        });
      },
      (error) => {
        this.handelBackEndError(error);
      }
    );
  }

  getDiscountTyps() {
    this.couponsService.getDiscountTypes().subscribe(
      (data) => {
        this.discountTypes = data;
      },
      (error) => {
        this.handelBackEndError(error);
      }
    );
  }

  getCouponClassifications() {
    this.couponsService.getCouponClassifications().subscribe(
      (data) => {
        this.classifications = data;
      },
      (error) => {
        this.handelBackEndError(error);
      }
    );
  }

  handelBackEndError(err) {
    try {
      console.log(err);
    } catch {
      console.log(err);
    }
  }

  changeProductSelection(event) {
    if (event.source._value.includes(0)) return;
    else {
      let selectedOptions = event.source.selected;
      if (selectedOptions.length < this.productsLength + 1)
        this.allSelected._selected = false;
    }
  }

  toggleAllSelection(elem) {
    if (elem._selected) {
      this.addCouponFormGroup.controls.services.patchValue([
        ...this.productType.map((item) => item.id),
      ]);
      elem._selected = true;
    } else {
      this.addCouponFormGroup.controls.services.patchValue([]);
    }
  }

  discountTypeChange(event) {
    if (event.value != 0) {
      document.querySelector('#discout_type').classList.remove('invalid-field');
    }
  }

  goCouponsList() {
    this.router.navigateByUrl('/pages/coupons/copoun-list');
    this.titleService.setTitle('اكواد الخصم | عاين');
  }

  checkLogicValidation() {
    var startDate = moment.utc(this.addCouponFormGroup.get('startDate').value);
    var endDate = moment.utc(this.addCouponFormGroup.get('endDate').value);

    if (startDate.isAfter(endDate)) {
      this.alertmsg = true;
      this.message = 'يجب ان يكون تاريخ الانتهاء بعد تاريخ البدء';
      return true;
    }
    return false;
  }

  closeError() {
    this.alertmsg = false;
  }

  codeValueChanged(event) {
    this.addCouponFormGroup.get(event.labelEn).setValue(event.value);
  }

  updateFormWithFormatedDate() {
    if (this.addCouponFormGroup.get('startDate').value) {
      this.formatedStartDate = moment(
        this.addCouponFormGroup.get('startDate').value
      );
      this.formatedStartDate = this.formatedStartDate.format('YYYY-MM-DD');
      this.addCouponFormGroup
        .get('start_date')
        .setValue(this.formatedStartDate);
    }

    if (this.addCouponFormGroup.get('endDate').value) {
      this.formatedEndDate = moment(
        this.addCouponFormGroup.get('endDate').value
      );
      this.formatedEndDate = this.formatedEndDate.format('YYYY-MM-DD');
      this.addCouponFormGroup.get('end_date').setValue(this.formatedEndDate);
    }
  }

  save() {
    this.alertmsg = false;
    let discount_type = this.addCouponFormGroup.get('discount_type').value;
    if (this.addCouponFormGroup.status == 'INVALID' || discount_type == 0) {
      this.alertmsg = true;
      this.message =
        'هناك خطأ في خانة أو بعض الخانات يرجى التأكد من المعلومات المدخلة';
      let fields = document.querySelectorAll(
        'input.ng-invalid:not(.mat-input-element) , select.ng-invalid , mat-form-field#products.ng-invalid, input.ng-invalid'
      );
      if (discount_type == 0) {
        document.querySelector('#discout_type').classList.add('invalid-field');
      }
      fields.forEach((field) => {
        field?.parentElement.classList.add('invalid-field');
      });
      return;
    }

    if (this.checkLogicValidation()) return;

    if (
      this.addCouponFormGroup.get('startDate').value ||
      this.addCouponFormGroup.get('endDate').value
    )
      this.updateFormWithFormatedDate();

    if (this.couponID > 0) {
      this.updateCoupon();
    } else this.addNewCoupon();
  }

  addNewCoupon() {
    this.couponsService.addNewCoupon(this.addCouponFormGroup.value).subscribe(
      (data) => {
        let fields = document.querySelector(' .codeName');
        fields.classList.remove('invalid-field');
        this.successImg = true;
        this.showAlertBox();
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  updateCoupon() {
    this.couponsService
      .updateCoupon(this.couponID, this.addCouponFormGroup.value)
      .subscribe(
        (data) => {
          this.successImg = true;
          this.showAlertBox();
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.successImg,
        message: this.confirmMsg,
        backUrl: '/pages/coupons/copoun-list',
      },
    });
  }

  setCouponServices(event) {
    if (event) {
      this.addCouponFormGroup.controls.services.patchValue([...event]);
      this.showDynamicServices = [...event].includes(this.orderType.DynamicServices);
      if(this.showDynamicServices) {
        this.setRequiredValidatorVal('dynamic_services');
      } else {
        this.clearValidators('dynamic_services');
      }
    }
  }

  setRequiredValidatorVal(formControl) {
    this.addCouponFormGroup.controls[formControl].setValidators(
      Validators.required
    );
    this.addCouponFormGroup.controls[
      formControl
    ].updateValueAndValidity();
  }

  clearValidators(formControl) {
    this.addCouponFormGroup.controls[formControl].clearValidators();
    this.addCouponFormGroup.controls[formControl].updateValueAndValidity();
  }

  handleError(error) {
    this.successImg = false;
    this.alertmsg = true;
    this.message = Object.values(error.error)[0].toString();
    let key = Object.keys(error.error)[0].toString();
    let field = document.querySelector(
      '[formControlName=' + key + ']'
    )?.parentElement;
    field?.classList.add('invalid-field');
    this.handelBackEndError(error);
  }
}
