import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends AYENServiceBase{

  constructor(private http: HttpClient) { 
    super();
  }

  deleteProject(projectIds: number[]) : Observable<any>{
    return this.http.request('DELETE', this.baseURL + "/admin/contracts/" , { body: projectIds } );
  }

  createProject(data): Observable<any> {
    return this.http.post<any>(this.baseURL + '/admin/contracts/', data);
  }

  updateProject(id, data): Observable<any> {
    return this.http.put<any>(this.baseURL + `/admin/contracts/${id}`, data);
  }

  getProjectByID(id): Observable<any> {
    return this.http.get<any>(this.baseURL + `/admin/contracts/${id}`);
  }

  getProjectsOrdersByPage(query): Observable<any>{
    return this.http.get(this.baseURL + '/admin/orders/business', {params: query});
  }

  getProjects(params): Observable<any> {
    return this.http.get<any>(this.baseURL + '/admin/contracts/', { params: params });
  }

  addOrdersProject(contractId, data): Observable<any> {
    return this.http.post(this.baseURL + '/admin/contracts/'+contractId+'/orders/bulk/', data);
  }

  getTableHeaderData(): Observable<any> {
    return this.http.get<any>(this.baseURL + '/admin/order-grid-column/');
  }

  customizeColumnGrid(payload:any): Observable<any> {
    return this.http.put<any>(this.baseURL + '/admin/order-grid-column/', payload);
  }
}
