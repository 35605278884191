<div class="main-card evalution-terms">
  @for(evaluationTerm of evaluationTerms; track evaluationTerm) {
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="" class="form-label">البند</label>
        <input type="text" class="form-control" readonly [value]="evaluationTerm?.term?.name" />
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="" class="form-label">نوع التقييم</label>
        <ng-select
          [items]="criteria"
          bindLabel="name"
          bindValue="id"
          placeholder="يرجى تحديد نوع التقييم "
          [multiple]="true"
          [(ngModel)]="evaluationTerm.criteria"
          [closeOnSelect]="false"
          (close)="setCriteria(evaluationTerm.criteria, evaluationTerm?.id)">
        </ng-select>
      </div>
    </div>
  </div>
  }
</div>
