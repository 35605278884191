import { Component, Input, OnInit } from '@angular/core';
import { InspectionEvaluationsService } from 'src/app/features/projects/services/inspection-evaluations.service';

@Component({
  selector: 'evaluation-terms',
  templateUrl: './evaluation-terms.component.html',
  styleUrl: './evaluation-terms.component.scss'
})
export class EvaluationTermsComponent implements OnInit {

  @Input() terms = [];
  criteria = [];

  constructor(private inspectionEvaluationsService: InspectionEvaluationsService) {}

  ngOnInit(): void {
    this.getCriteria()
  }

  getCriteria() {
    this.inspectionEvaluationsService.getCriteria().subscribe(
      data => {
        this.criteria = data.reduce((criteriaMap, item) => {
          criteriaMap[item.id] = item.name;
          return criteriaMap;
        }, {});
      }
    );
  }
}
