
<div class="inner-page-header"><h3>{{title}} </h3></div>
@if (showForm) {
  <form class="needs-validation" id="general-form" [formGroup]="addCouponFormGroup"
    (ngSubmit)="save()">
    <div class="main-card">
      <div class="row">
        <div class="col-md-6">
          <app-switch-input
            [label]="'حالة الكود'"
            [labelEn] = "'status'"
            [labelDesc]="'(مفعل/غير مفعل)'"
            [value]="status"
            (valueChanged) = "codeValueChanged($event)">
          </app-switch-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label">
              كود الخصم
              <span class="red">*</span>
            </label>
            <input type="text" class="form-control codeName" formControlName="code" />
            <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label">
              النص التوضيحي
              <span class="red">*</span>
              <span class="label-desc">(30 حرف كحد أقصى)</span>
            </label>
            <input type="text" class="form-control" formControlName="description" />
            <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label"> المنتج/الخدمة<span class="red">*</span></label>
            <app-select
              [optionItems]="productType"
              [bindValue]="'pk'"
              [placeholder]="'يرجى تحديد  المنتج المرتبط بالكود'"
              [initValues] = "couponDetails?.services"
              (selectedOptions)="setCouponServices($event)">
            </app-select>
          </div>
        </div>
        @if(showDynamicServices) {
          <div class="col-md-6 custom-control">
            <div class="form-group">
              <label for="" class="form-label"> الخدمات<span class="red">*</span></label>
              <app-select
                [optionItems]="dynamicServicesArr"
                [placeholder]="'يرجى تحديد خدمات البنود'"
                [id]="'dynamic_services'"
                [bindLabel]="'name'"
                [bindValue]="'id'"
                [dynamicServicesItems]="dynamicServicesItems"
                [initValues]="initDynamicServices"
                (selectedOptions)="setDynamicServices($event)"
                (onScrollValues)="onScrollOptions($event)"
                (updateOptions)="updateDynamicServices($event)"
                (openSelectEmitter)="getDynamicServices()"
                >
              </app-select>
            </div>
          </div>
        }
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label"> تصنيف الكود</label>
            <ng-select bindLabel="name" class="single-select"
              placeholder="  يرجى تحديد  تصنيف الكود  "
              appendTo="body"
              formControlName="classification"
              [searchable]="true"
              [clearable]="true">
              @for (class of classifications; track class) {
                <ng-option [value]="class.id" >
                  {{class.name}}
                </ng-option>
              }
            </ng-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label"> نوع الخصم<span class="red">*</span></label>
            <ng-select bindLabel="name" class="single-select"
              placeholder="  يرجى تحديد  نوع الخصم  "
              appendTo="body"
              formControlName="discount_type"
              [searchable]="true"
              [clearable]="true" (Change)="discountTypeChange($event)">
              @for (disc of discountTypes; track disc) {
                <ng-option [value]="disc.id" >
                  {{disc.name}}
                </ng-option>
              }
            </ng-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label">
              قيمة الخصم
              <span class="red">*</span>
            </label>
            <input type="number" class="form-control" formControlName="discount_value" />
            <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
          </div>
        </div>
        @if (discountType != 1) {
          <div class="col-md-6">
            <div class="form-group">
              <label for="" class="form-label">
                أقصى مبلغ يمكن خصمه
                <div class="custom-tooltip">
                  <img src="assets/images/svg/info-icon.svg" alt="" />
                  <span class="tooltip-text">لتحديد أعلى قيمة يمكن خصمها ضمن الطلب في حال عدم التحديد لن يكون هناك حد لمبلغ الخصم</span>
                </div>
              </label>
              <input type="number" class="form-control" formControlName="maximum_discount_amount" />
            </div>
          </div>
        }
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label">
              الحد الأقصى لعدد مرات الإستخدام
            </label>
            <input type="number" class="form-control" formControlName="maximum_redeem_number" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label"> البطاقات البنكية</label>
            <ng-select bindLabel="name" class="single-select"
              placeholder="يرجى تحديد البطاقات البنكية المتعلقة بالكود"
              appendTo="body"
              formControlName="bank"
              [searchable]="true"
              [clearable]="true">
              @for (bank of bankdata; track bank) {
                <ng-option [value]="bank.pk" >
                  {{bank.name}}
                </ng-option>
              }
            </ng-select>
          </div>
        </div>
        <div class="col-md-6">
          <p class="custom-lable">أطراف تحمل الخصم</p>
          <div class="droppoints">
            <div class="dropItem radio-div">
              <input type="radio"
                id="BOTH"
                formControlName="involved_parties"
                name="involved_parties"
                value="BOTH"
                >
                <label for="BOTH"> مشترك</label>
              </div>
              <div class="dropItem radio-div">
                <input type="radio"
                  id="AYEN"
                  formControlName="involved_parties"
                  name="involved_parties"
                  value="AYEN"
                  >
                  <label for="AYEN">عاين</label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="form-label">تاريخ البدء</label>
                <mat-form-field (click)="picker.open()" appearance="outline">
                  <input matInput formControlName="startDate"  [matDatepicker]="picker" disabled />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                    >
                    <mat-icon matDatepickerToggleIcon class="calender-icon">
                      <img src="assets/images/svg/calendar-admin-icon.svg" alt="">
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker disabled="false" ></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="form-label"> تاريخ إنتهاء</label>
                <mat-form-field (click)="picker_end.open()"  appearance="outline">
                  <input matInput formControlName="endDate" [matDatepicker]="picker_end" disabled />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker_end">
                    <mat-icon matDatepickerToggleIcon class="calender-icon">
                      <img src="assets/images/svg/calendar-admin-icon.svg" alt="">
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker_end disabled="false" ></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          @if (alertmsg) {
            <div class="alert-box">
              <img
                src="assets/images/svg/error-icon.svg"
                class="close-alert"
                (click)="closeError()"
                alt=""
                />
                <p class="alert-msg">{{message}}</p>
              </div>
            }
          </div>
          <div  class="row">
            <div class="col-md-12 double-action">
              <button class="btn btn-cancel" type="button" (click)="goCouponsList()">
                رجوع
              </button>
              <button class="btn btn-green" id='saveBtn'
                type="submit">
                {{saveBtn}}
              </button>
            </div>
          </div>
        </form>
      }
