import { Component, Input, OnInit } from '@angular/core';
import { InspectionEvaluationsService } from '../../services/inspection-evaluations.service';

@Component({
  selector: 'evaluation-terms',
  templateUrl: './evaluation-terms.component.html',
  styleUrl: './evaluation-terms.component.scss'
})
export class EvaluationTermsComponent implements OnInit {

  @Input() evaluationID: any;
  evaluationTerms = [];
  criteria = [];

  constructor(private inspectionEvaluationsService: InspectionEvaluationsService) { }

  ngOnInit() {
    this.getTerms();
    this.getCriteria();
  }

  getTerms() {
    this.inspectionEvaluationsService.getInspectionEvaluationTerms(this.evaluationID).subscribe(
      data => {
        this.evaluationTerms = [...data];
      }
    )
  }

  getCriteria() {
    this.inspectionEvaluationsService.getCriteria().subscribe(
      data => {
        this.criteria = [...data];
      }
    );
  }

  setCriteria(data, evaluationTermId) {
    const termCriteria = {
      criteria: [...data]
    }
    this.inspectionEvaluationsService
      .updateInspectionEvaluationTerm(evaluationTermId, termCriteria)
      .subscribe( data => {

      }, error => {
        try {}
        catch{}
      });
  }
}
