<div class="inner-page-header">
  <h3>{{mainTitle}} خدمة</h3>
</div>
<div class="new-service-wrapper">
  <form [formGroup]="addServiceFormGroup">
    <div class="main-card">
      <div class="service-tools">
        <app-switch-input
          [label]="'حالة الخدمة'"
          [labelEn]="'is_active'"
          [labelDesc]="'(مفعل/غير مفعل)'"
          [index]="0"
          [value]="isActive"
          (valueChanged)="isActiveValueChanged($event)"
        >
        </app-switch-input>
        <div class="header-actions d-flex">
          @if (!addStatus) {
          <button class="btn btn-green" (click)="goToSettings()">
            <img
              class="setting-img"
              src="assets/images/svg/setting-def.svg"
              alt="settings"
            />
            إعدادت الخدمة
          </button>
          <div class="services-managment">
            <button
              class="btn btn-add"
              mat-button
              [matMenuTriggerFor]="orderMenu"
            >
              إدارة الخدمات
              <img src="assets/images/svg/dropDownArrow.svg" alt="" />
            </button>
            <mat-menu #orderMenu="matMenu">
              <a (click)="updateCategories()" mat-menu-item>
                تعديل التصنيفات
              </a>
              <a (click)="redistrebuteRatios()" mat-menu-item>
                اعادة توزيع النسب
              </a>
              <a (click)="openServiceCredentials()" mat-menu-item>
                اشترطات تقديم الخدمة
              </a>
            </mat-menu>
          </div>
          }
        </div>
      </div>
      <div class="service-data">
        <div class="form-group">
          <label for="" class="form-label">اسم الخدمة</label>
          <input
            type="text"
            formControlName="name"
            class="form-control"
            placeholder="يرجى كتابة اسم الخدمة"
            [ngClass]="{
              'invalid-field':
                addServiceFormGroup.controls['name'].errors && alertmsg
            }"
          />
        </div>
        <div class="form-group">
          <label for="" class="form-label"> النص التوضيحى</label>
          <input
            type="text"
            formControlName="explantion_txt"
            class="form-control"
            placeholder="يرجى كتابة نص توضيحى"
            [ngClass]="{
              'invalid-field':
                addServiceFormGroup.controls['explantion_txt'].errors && alertmsg
            }"
          />
        </div>
        <div class="form-group">
          <label for="" class="form-label">حالة إظهار البنود</label>
          <ng-select
            bindLabel="name"
            class="single-select"
            placeholder=""
            appendTo="body"
            formControlName="show_terms"
            [searchable]="true"
            [clearable]="true"
          >
            @for (choice of choicesList; track choice) {
            <ng-option [value]="choice.id">
              {{ choice.name }}
            </ng-option>
            }
          </ng-select>
        </div>
        <div class="form-group">
          <label for="" class="form-label">اسماء التقييم</label>
          <a class="add-item" (click)="addCompletionStatus()"
          ><img alt="" src="/assets/images/svg/add-icon-blue.svg" /> إضافة نوع تقييم
        </a>
          <ng-select
            bindLabel="name"
            class="single-select"
            placeholder=""
            appendTo="body"
            formControlName="completion_status"
            [searchable]="true"
            [clearable]="true"
          >
            @for (choice of statusCustomChoices; track choice) {
            <ng-option [value]="choice.id">
              {{ choice.name }}
            </ng-option>
            }
          </ng-select>
        </div>
        <div class="form-group">
          <label for="" class="form-label"> بيانات العقار </label>
          <app-select
            [optionItems]="buildingDetails"
            [placeholder]="'يرجى تحديد بيانات العقار'"
            [id]="'buildingDetails'"
            [initValues]="initBuildingDetails"
            [maxLength]="2"
            (selectedOptions)="setBuildingDetails($event)"
          >
          </app-select>
        </div>
      </div>

      <div class="single-action">
        <button class="btn btn-green" (click)="save()">حفظ</button>
      </div>

      @if (alertmsg) {
      <div class="alert-box">
        <img
          src="assets/images/svg/error-icon.svg"
          class="close-alert"
          (click)="closeError()"
          alt=""
        />
        <p class="alert-msg">{{ message }}</p>
      </div>
      }
    </div>
    <div formArrayName="categories" class="panel-wrapper">
      @for ( category of categories().controls; track category; let
      categoryIndex = $index) {
      <mat-accordion>
        <mat-expansion-panel
          (opened)="(true)"
          (closed)="(false)"
          [formGroupName]="categoryIndex"
          class="company-accordion"
        >
          <mat-expansion-panel-header class="header-parent">
            <mat-panel-title>
              <div>
                {{ category.value.name }}
                <span>({{ category.value.ratio }}%)</span>
              </div>
              <img
                alt=""
                src="assets/images/svg/wrench.svg"
                (click)="updateCategories(); $event.stopPropagation()"
                alt=""
              />
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="panel-body company-panel">
            @for (item of terms(categoryIndex).controls; track item; let i =
            $index) {
            <div class="new-row" formArrayName="terms">
              <ng-container [formGroupName]="i">
                <div class="item-wrapper">
                  <label for="" class="form-label">البند</label>
                  <input
                    type="text"
                    formControlName="name"
                    class="form-control"
                    placeholder=" يرجى كتابة البند"
                    [ngClass]="{
                      'invalid-field':
                        item['controls']['name'].errors && alertmsg
                    }"
                  />
                </div>
                <div class="item-wrapper">
                  <label for="" class="form-label">المرجعية</label>
                  <input
                    type="text"
                    formControlName="description"
                    class="form-control"
                    placeholder="يرجى كتابة المرجعية "
                    [ngClass]="{
                      'invalid-field':
                        item['controls']['description'].errors && alertmsg
                    }"
                  />
                </div>
                <div>
                  <label for="" class="form-label">نسبة التأثير</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      aria-label=""
                      formControlName="ratio"
                      placeholder="نسبة التصنيف"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
                <div>
                  <label for="" class="form-label">تكلفة الإصلاح</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      aria-label=""
                      formControlName="repairing_cost"
                      placeholder=" تكلفه الإصلاح"
                    />
                  </div>
                </div>
              </ng-container>
              <div class="action-wrapper">
                @if (i == 0) {
                <button class="btn btn-add" (click)="addItem(categoryIndex)">
                  <img alt="" src="assets/images/svg/add-service.svg" />
                </button>
                } @else {
                <button
                  class="btn btn-add"
                  (click)="deleteItem(categoryIndex, i)"
                >
                  <img alt="" src="assets/images/svg/DELETE1.svg" />
                </button>
                }
                <button
                  class="btn btn-add"
                  (click)="changeCategory(item['controls'], categoryIndex)"
                >
                  <img alt="" src="assets/images/svg/edit-icon.svg" />
                </button>
              </div>
            </div>
            } @if (errorMsg && selectedAccordion == categoryIndex) {
            <div class="alert-box">
              <img
                src="assets/images/svg/error-icon.svg"
                class="close-alert"
                (click)="closeError()"
                alt=""
              />
              <p class="alert-msg">{{ message }}</p>
            </div>
            }
            <button
              class="btn btn-green"
            
              (click)="
                saveCategory(categoryIndex); selectedAccordion = categoryIndex
              "
            >
              حفظ
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      }
    </div>
  </form>
</div>
