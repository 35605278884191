import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectsService } from '../../services/projects.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { ActivatedRoute, Router } from '@angular/router';
import { InspectionEvaluationsService } from '../../services/inspection-evaluations.service';

@Component({
  selector: 'app-evaluation-details',
  templateUrl: './evaluation-details.component.html',
  styleUrl: './evaluation-details.component.scss'
})
export class EvaluationDetailsComponent implements OnInit {

  inspectionEvaluationFormGroup: UntypedFormGroup;
  projects = [];
  dynamicServices = [];
  projectsConfig = {
    currentPage: 1,
    pageCount: 1,
  };
  projectSearchInput: string;
  timeout: any = null;
  @ViewChild('selectProject') selectProject: NgSelectComponent;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  isNewEvaluation = true;
  evaluationID: any;
  alertmsg: boolean = false;
  message: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private projectService: ProjectsService,
    private inspectionEvaluationsService: InspectionEvaluationsService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {

      if (params['id'] != 'add') {
        this.isNewEvaluation = false;
        this.evaluationID = params['id'];
        this.getInspectionEvaluationByID(this.evaluationID);
      } else {
        this.isNewEvaluation = true;
      }
    });

    this.getProjects();
    this.createForm();
    this.createFormControls();
  }

  createForm() {
    this.inspectionEvaluationFormGroup = this.formBuilder.group({
      contract: [null, Validators.required],
      dynamic_service: [null, Validators.required],
      is_active: [true]
    });
  }

  createFormControls() {
    return this.inspectionEvaluationFormGroup.controls;
  }

  getProjects() {
    let params = {
      page: this.projectsConfig.currentPage,
    }

    if(this.projectSearchInput) {
      params['query'] = this.projectSearchInput;
    }

    this.projectService.getProjects(params).subscribe(data => {
      this.projects.push(...data.results);
      this.projectsConfig['pageCount'] = data.pages_count;
    }, error => {
      try { }
      catch { }
    });
  }

  onScrollPagination() {
    if (this.projectsConfig.currentPage < this.projectsConfig.pageCount) {
      this.projectsConfig.currentPage++;
      this.getProjects();
    }
  }

  resetSearch() {
    this.projects = [];
    this.projectsConfig.currentPage = 1;
    this.projectSearchInput = null;
    this.getProjects();
  }

  searchText() {
    this.projectSearchInput = this.selectProject.searchTerm;
    this.projects = [];
    clearTimeout(this.timeout);
    let that = this;
    that.timeout = setTimeout(() => {
      if (that.projectSearchInput != '') {
        that.getProjects();
      } else {
        this.projectsConfig.currentPage = 1;
        that.getProjects();
      }
    }, 600);
  }

  getProjectDetails(projectID) {
    this.projectService.getProjectByID(projectID)
  }

  evaluationStatus(event) {
    this.inspectionEvaluationFormGroup.get(event.labelEn).setValue(event.value);
  }

  onProjectChanged(contractID) {
    this.inspectionEvaluationFormGroup.get('dynamic_service').setValue(null);
    if(contractID) {
      this.inspectionEvaluationsService.getProjectServices(contractID).subscribe(
        data => {
          this.dynamicServices = [...data];
        }
      ); 
    }
  }

  getInspectionEvaluationByID(id) {
    this.inspectionEvaluationsService.getInspectionEvaluationByID(id).subscribe(
      data => {
        this.patchValues(data);
      }
    );
  }

  patchValues(data) {
    if (data?.contract?.id) {
      let obj = {
        id: data?.contract?.id,
        name: data?.contract?.name,
      };
      this.projects = [];
      this.projects.push(obj);
      this.onProjectChanged(data.contract.id);
    }
    this.inspectionEvaluationFormGroup.patchValue({
      contract: data.contract?.id,
      dynamic_service: data.dynamic_service?.id,
      is_active: data.is_active
    });
  }

  save() {
    this.alertmsg = false;
    if(this.inspectionEvaluationFormGroup.invalid) {
      this.alertmsg = true;
      this.message = 'هناك خطأ في خانة أو بعض الخانات يرجى التأكد من المعلومات المدخلة';
      return;
    }

    if (this.isNewEvaluation) {
      this.createInspectionEvaluation();
    } else {
      this.updateInspectionEvaluation();
    }
  }

  closeError() {
    this.alertmsg = false;
  }

  createInspectionEvaluation() {
    this.inspectionEvaluationsService.createInspectionEvaluation(this.inspectionEvaluationFormGroup.value).subscribe(
      data => {
        this.router.navigate([`/pages/projects/evaluation/${data.id}`], {
          replaceUrl: true,
        });
      }, (error) => {
        this.showAlertBox({
          successImg: false,
          confirmMsg: 'برجاء المحاولة مرة اخرى',
          button: 'تم'
        });
      }
    );
  }

  updateInspectionEvaluation() {
    this.inspectionEvaluationsService.putInspectionEvaluation(
      this.evaluationID, this.inspectionEvaluationFormGroup.value).subscribe(
      data => {
        this.showAlertBox({
          successImg: true,
          confirmMsg: 'تم العديل بنجاح',
          button: 'تم'
        });
      }, (error) => {
        this.showAlertBox({
          successImg: false,
          confirmMsg: 'برجاء المحاولة مرة اخرى',
          button: 'تم'
        });
      }
    )
  }

  showAlertBox(params) {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: params.successImg,
        message: params.confirmMsg,
        button: params.button,
      },
    });
  }
}
