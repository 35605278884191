<div class="inner-page-header">
  @if(isNewEvaluation) {
    <h3>إضافة تقييم تلقائى لفحص</h3>
  } @else {
    <h3>تعديل التقييم التلقائى لفحص</h3>
  }
</div>
<form
  class="needs-validation"
  id="general-form"
  [formGroup]="inspectionEvaluationFormGroup"
  (ngSubmit)="save()"
  >
  <div class="main-card">
    <div class="row">
      <div class="col-6">
        <app-switch-input
            [label]="'حالة التقييم'"
            [labelEn]="'is_active'"
            [value]="inspectionEvaluationFormGroup.controls['is_active'].value"
            (valueChanged)="evaluationStatus($event)"
            >
          </app-switch-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">المشروع</label>
          <ng-select
            #selectProject
            bindLabel="name"
            (scrollToEnd)="onScrollPagination()"
            class="single-select"
            formControlName="contract"
            placeholder="يرجى كتابة اسم المشروع"
            [searchable]="true"
            (removed)="resetSearch()"
            (search)="searchText()"
            (clear)="resetSearch()"
            (change)="onProjectChanged($event)"
            [virtualScroll]="true"
            [clearable]="true"
            [readonly]="!isNewEvaluation"
            >
            @for (project of projects; track project) {
              <ng-option [value]="project.pk">
                {{ project.name }}
              </ng-option>
            }
          </ng-select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">الخدمة</label>
          <ng-select 
            bindLabel="name" 
            class="single-select"
            #selectService
            placeholder="يرجى اختيار الخدمة "
            appendTo="body"
            formControlName="dynamic_service"
            [searchable]="true"
            [clearable]="true"
            [readonly]="!isNewEvaluation">
            @for (dynamicService of dynamicServices; track dynamicService) {
              <ng-option [value]="dynamicService.id" >
                {{dynamicService.name}}
              </ng-option>
            }
          </ng-select>
        </div>
      </div>
    </div>
    <div class="single-action">
      <button class="btn btn-green" type="submit">حفظ</button>
    </div>
    @if (alertmsg) {
    <div class="alert-box">
      <img
        src="assets/images/svg/error-icon.svg"
        class="close-alert"
        (click)="closeError()"
        alt=""
      />
      <p class="alert-msg">{{ message }}</p>
    </div>
    }
  </div>
</form>
@if(evaluationID) {
  <evaluation-terms [evaluationID]="evaluationID"></evaluation-terms>
}
