<div class="report-log">
  <table class="table" aria-label="">
    <thead>
      <tr>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      @for (term of terms; track term; let i = $index) {
      <tr>
        <td class="width-60">{{term.term}}</td>
        <td class="width-40">{{criteria[term.criteria]}}</td>
      </tr>
      }
    </tbody>
  </table>
</div>
