@if (spinner) {
<app-spinner></app-spinner>
}
<div class="inner-page-header">
  <h3>طلبات المشاريع</h3>
</div>
<div class="request-control">
  <div class="right-side">
    @if (filterData) {
    <app-filter
      [filterData]="filterData"
      [filterDataInit]="filterDataInit"
      [filterPage]="filterPage"
      (formValus)="filterFormValues($event)"
    >
    </app-filter>
    }
    <dynamic-columns></dynamic-columns>
    <quick-search (searchResults)="quickSearch($event)"></quick-search>
  </div>
  <div class="left-side">
    <order-status-menu
      [selectedRowsPK]="selectedRowsPK"
      (changedOrder)="updateOrderStatus($event)"
    ></order-status-menu>
    <add-order></add-order>
    <orders-management
      [selectedRowsPK]="selectedRowsPK"
      [isB2B]="true"
      [assignedInspectorId]="assignedInspectorId"
      [pageIdentifier]="'business'"
      (updateOrders)="getProjectsOrdersByPage()"
      (sipnnerState)="sipnnerState($event)"
    >
    </orders-management>
  </div>
</div>
<div class="saved-filters">
  <saved-filters></saved-filters>
</div>
@if (orders.length > 0) {
<div class="request-table">
  <div class="table-responsive">
    <ayen-table
      [tableData]="orderData"
      [paginationConfig]="config"
      (disabledDelete)="disabledDeleteBtn($event)"
      (checkedRow)="getSelectedRow($event)"
      (sortingCol)="toggleSorting($event)"
      [pageUrl]="'/pages/orders/'"
    >
    </ayen-table>
  </div>
</div>
} @if (orders && orders.length > 0) {
<ayen-pagination
  (pageData)="pageChanged($event)"
  (pageSize)="pageSizeChanged($event)"
  [totalCount]="config.totalItems"
  [pageTitle]="pageTitle"
>
</ayen-pagination>
}
