import { UserType } from '../../models/enums/user-type.enum';

export class SideBarMenu {
  public static DROPDOWNS = {
    orders: false,
    projects: false,
    inspectors: false,
    coupons: false,
    notificatios: false,
  };

  public static SIDEBAR_MENU_DATA = {
    [UserType.Admin]: [
      {
        sideItem: 'الرئيسية',
        imgIcon: 'assets/images/svg/home.svg',
        pageTile: 'الرئيسية | عاين',
        sidebarstatus: 'home',
        url: '',
        isSubMenu: false,
        subMenuItems: [],
      },
      {
        sideItem: 'الطلبات',
        imgIcon: 'assets/images/svg/orders.svg',
        pageTile: '',
        sidebarstatus: 'orders',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'الطلبات المعتمدة',
            pageTile: 'الطلبات | عاين',
            sidebarstatus: 'orders',
            url: '/pages/orders/orders-list',
          },
          {
            item: 'الطلبات التجربية',
            pageTile: 'الطلبات التجربية | عاين',
            sidebarstatus: 'servicesOrders',
            url: '/pages/orders/services-orders',
          },
        ],
      },
      {
        sideItem: 'العملاء',
        imgIcon: 'assets/images/svg/account.svg',
        pageTile: 'العملاء | عاين',
        sidebarstatus: 'customers',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'العملاء ',
            pageTile: 'العملاء | عاين',
            sidebarstatus: 'customers-grid',
            url: '/pages/customers/customers-grid',
          },
          {
            item: 'تقيمات العملاء',
            pageTile: 'تقيمات العملاء  | عاين',
            sidebarstatus: 'customers-rating',
            url: '/pages/customers/customers-rating',
          },
        ],
      },
      {
        sideItem: 'المشاريع',
        imgIcon: 'assets/images/svg/projects.svg',
        pageTile: '',
        sidebarstatus: 'projects',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'طلبات المشاريع',
            pageTile: ' طلبات المشاريع | عاين',
            sidebarstatus: 'projects',
            url: '/pages/projects/orders',
          },
          {
            item: 'المشاريع',
            pageTile: 'المشاريع | عاين',
            sidebarstatus: 'projects-list',
            url: '/pages/projects/list',
          },
          {
            item: 'التقييم التلقائي للفحوصات ',
            pageTile: 'التقييم التلقائي للفحوصات  | عاين',
            sidebarstatus: 'evaluations',
            url: '/pages/projects/evaluations',
          },
        ],
      },
      {
        sideItem: 'تسعير الباقات',
        imgIcon: 'assets/images/svg/projects.svg',
        pageTile: 'تسعير الباقات | عاين',
        sidebarstatus: 'pricing',
        url: '/pages/pricing/list',
        isSubMenu: false,
        subMenuItems: [],
      },
      {
        sideItem: 'الخدمات',
        imgIcon: 'assets/images/svg/projects.svg',
        pageTile: 'الخدمات | عاين',
        sidebarstatus: 'services',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'جميع الخدمات ',
            pageTile: ' الخدمات  | عاين',
            sidebarstatus: 'services',
            url: '/pages/services/list',
          },
          {
            item: 'الإضافات',
            pageTile: '  الإضافات | عاين',
            sidebarstatus: 'additionals',
            url: '/pages/services/additions',
          },
        ],
      },
      {
        sideItem: 'الشركات',
        imgIcon: 'assets/images/svg/company-icon.svg',
        pageTile: 'الشركات | عاين',
        sidebarstatus: 'company',
        url: '/pages/company/list',
        isSubMenu: false,
        subMenuItems: [],
      },
      {
        sideItem: 'الفاحصين',
        imgIcon: 'assets/images/svg/inspector.svg',
        pageTile: '',
        sidebarstatus: 'inspectors',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'جميع الفاحصين',
            pageTile: 'الفاحصين | عاين',
            sidebarstatus: 'inspectors-grid',
            url: '/pages/inspectors/inspectors-grid',
          },
          {
            item: 'التأهيل',
            pageTile: 'المؤهلين | عاين',
            sidebarstatus: 'qualified-grid',
            url: '/pages/inspectors/qualified',
          },
          {
            item: ' احصائيات الفاحصين',
            pageTile: 'احصائيات الفاحصين | عاين',
            sidebarstatus: 'inspectors-settings',
            url: '/pages/inspectors/inspectors-statistics',
          },
          {
            item: ' اعدادات الفاحصين',
            pageTile: 'اعدادات الفاحصين | عاين',
            sidebarstatus: 'inspectors-stats',
            url: '/pages/inspectors/settings',
          },
        ],
      },
      {
        sideItem: 'أكواد الخصم',
        imgIcon: 'assets/images/svg/descount.svg',
        pageTile: '',
        sidebarstatus: 'coupons',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'جميع الأكود',
            pageTile: 'اكواد | الخصم',
            sidebarstatus: 'coupon-list',
            url: '/pages/coupons/copoun-list',
          },
          {
            item: 'البطاقات البنكية',
            pageTile: 'البطاقات البنكية | عاين',
            sidebarstatus: 'bank-list',
            url: '/pages/coupons/bank-list',
          },
        ],
      },
      {
        sideItem: 'تحديثات النظام',
        imgIcon: 'assets/images/svg/whats-new.svg',
        pageTile: 'تحديثات النظام | عاين',
        sidebarstatus: 'whats-new',
        url: '/pages/whats-new/whats-new-grid',
        isSubMenu: false,
        subMenuItems: [],
      },
      {
        sideItem: 'مركز الإشعارات',
        imgIcon: 'assets/images/svg/NOTIFICATION-s.svg',
        pageTile: '',
        sidebarstatus: 'notificatios',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'إشعارات داخلية',
            pageTile: 'مركز الإشعارات | عاين',
            sidebarstatus: 'notification-list',
            url: '/pages/notification-center/notification-list',
          },
          {
            item: 'إشعارات المستعرض',
            pageTile: 'مركز الإشعارات | عاين',
            sidebarstatus: 'notificationBrowser-list',
            url: '/pages/notification-center/notification-list/true',
          },
        ],
      },
    ],
    [UserType.Markting]: [
      {
        sideItem: 'رسائل نصية',
        imgIcon: 'assets/images/svg/home.svg',
        pageTile: 'رسائل نصية | عاين',
        sidebarstatus: 'sms',
        url: 'pages/markting/send-sms',
        isSubMenu: false,
        subMenuItems: [],
      },
    ],
    [UserType.Assistant]: [
      {
        sideItem: 'الطلبات',
        imgIcon: 'assets/images/svg/orders.svg',
        pageTile: '',
        sidebarstatus: 'orders',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'الطلبات المعتمدة',
            pageTile: 'الطلبات | عاين',
            sidebarstatus: 'orders',
            url: '/pages/orders/orders-list',
          },
        ],
      },
      {
        sideItem: 'المشاريع',
        imgIcon: 'assets/images/svg/projects.svg',
        pageTile: '',
        sidebarstatus: 'projects',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'طلبات المشاريع',
            pageTile: ' طلبات المشاريع | عاين',
            sidebarstatus: 'projects',
            url: '/pages/projects/orders',
          },
        ],
      },
    ],
    [UserType.Supervisor]: [
      {
        sideItem: 'الرئيسية',
        imgIcon: 'assets/images/svg/home.svg',
        pageTile: 'الرئيسية | عاين',
        sidebarstatus: 'home',
        url: '',
        isSubMenu: false,
        subMenuItems: [],
      },
      {
        sideItem: 'الطلبات',
        imgIcon: 'assets/images/svg/orders.svg',
        pageTile: '',
        sidebarstatus: 'orders',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'الطلبات المعتمدة',
            pageTile: 'الطلبات | عاين',
            sidebarstatus: 'orders',
            url: '/pages/orders/orders-list',
          },
        ],
      },
      {
        sideItem: 'المشاريع',
        imgIcon: 'assets/images/svg/projects.svg',
        pageTile: '',
        sidebarstatus: 'projects',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'طلبات المشاريع',
            pageTile: ' طلبات المشاريع | عاين',
            sidebarstatus: 'projects',
            url: '/pages/projects/orders',
          },
          {
            item: 'المشاريع',
            pageTile: 'المشاريع | عاين',
            sidebarstatus: 'projects-list',
            url: '/pages/projects/list',
          },
        ],
      },
      {
        sideItem: 'العملاء',
        imgIcon: 'assets/images/svg/account.svg',
        pageTile: 'العملاء | عاين',
        sidebarstatus: 'customers',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'تقيمات العملاء',
            pageTile: 'تقيمات العملاء  | عاين',
            sidebarstatus: 'customers-rating',
            url: '/pages/customers/customers-rating',
          },
        ],
      },
      {
        sideItem: 'الفاحصين',
        imgIcon: 'assets/images/svg/inspector.svg',
        pageTile: '',
        sidebarstatus: 'inspectors',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'جميع الفاحصين',
            pageTile: 'الفاحصين | عاين',
            sidebarstatus: 'inspectors-grid',
            url: '/pages/inspectors/inspectors-grid',
          },
          {
            item: 'التأهيل',
            pageTile: 'المؤهلين | عاين',
            sidebarstatus: 'qualified-grid',
            url: '/pages/inspectors/qualified',
          },
          {
            item: ' احصائيات الفاحصين',
            pageTile: 'احصائيات الفاحصين | عاين',
            sidebarstatus: 'inspectors-settings',
            url: '/pages/inspectors/inspectors-statistics',
          },
          {
            item: ' اعدادات الفاحصين',
            pageTile: 'اعدادات الفاحصين | عاين',
            sidebarstatus: 'inspectors-stats',
            url: '/pages/inspectors/settings',
          },
        ],
      },
    ],
    [UserType.OrderDataEntry]: [
      {
        sideItem: 'المشاريع',
        imgIcon: 'assets/images/svg/projects.svg',
        pageTile: '',
        sidebarstatus: 'projects',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'طلبات المشاريع',
            pageTile: ' طلبات المشاريع | عاين',
            sidebarstatus: 'projects',
            url: '/pages/projects/orders',
          },
        ],
      },
    ],
    [UserType.AssignmentOffice]: [
      {
        sideItem: 'المشاريع',
        imgIcon: 'assets/images/svg/projects.svg',
        pageTile: '',
        sidebarstatus: 'projects',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'المشاريع',
            pageTile: 'المشاريع | عاين',
            sidebarstatus: 'projects-list',
            url: '/pages/projects/list',
          },
          {
            item: 'طلبات المشاريع',
            pageTile: ' طلبات المشاريع | عاين',
            sidebarstatus: 'projects',
            url: '/pages/projects/orders',
          },
        ],
      },
    ],
    [UserType.InspectorActivationOffice]: [
      {
        sideItem: 'الفاحصين',
        imgIcon: 'assets/images/svg/inspector.svg',
        pageTile: '',
        sidebarstatus: 'inspectors',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'جميع الفاحصين',
            pageTile: 'الفاحصين | عاين',
            sidebarstatus: 'inspectors-grid',
            url: '/pages/inspectors/inspectors-grid',
          },
        ],
      },
      {
        sideItem: 'المشاريع',
        imgIcon: 'assets/images/svg/projects.svg',
        pageTile: '',
        sidebarstatus: 'projects',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'المشاريع',
            pageTile: 'المشاريع | عاين',
            sidebarstatus: 'projects-list',
            url: '/pages/projects/list',
          },
        ],
      },
    ],
    [UserType.SupervisorQC]: [
      {
        sideItem: 'المشاريع',
        imgIcon: 'assets/images/svg/projects.svg',
        pageTile: '',
        sidebarstatus: 'projects',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'طلبات المشاريع',
            pageTile: ' طلبات المشاريع | عاين',
            sidebarstatus: 'projects',
            url: '/pages/projects/orders',
          }
        ],
      },
    ],
    [UserType.QC]: [
      {
        sideItem: 'المشاريع',
        imgIcon: 'assets/images/svg/projects.svg',
        pageTile: '',
        sidebarstatus: 'projects',
        url: '',
        isSubMenu: true,
        subMenuItems: [
          {
            item: 'طلبات المشاريع',
            pageTile: ' طلبات المشاريع | عاين',
            sidebarstatus: 'projects',
            url: '/pages/projects/orders',
          }
        ],
      },
    ]
  };
}
