import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { InspectionService } from 'src/app/core/services/inspection/inspection.service';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';
import * as moment from 'moment';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { Select, Store } from '@ngxs/store';
import { OrderState } from '../../state/order.state';
import { Observable, Subscription } from 'rxjs';
import { UpdateOrderDetails } from '../../state/order-action';
import { OrderType } from 'src/app/models/enums/order-type.enum';
import { SpinnerLoaderService } from 'src/app/core/services/spinner-loader/spinner-loader.service';

@Component({
  selector: 'order-main-details',
  templateUrl: './order-main-details.component.html',
  styleUrls: ['./order-main-details.component.scss'],
})
export class OrderMainDetailsComponent implements OnInit, OnDestroy {
  buildingTypes: any;
  inspectors: any[] = [];
  status: any;
  orderStatus: any;
  inspectionPeriods: any;
  Inspection_purpose: any;
  orderMainDetailsFormGroup: UntypedFormGroup;
  @Output() enableSave = new EventEmitter();
  minDate = new Date();
  disableDate: boolean = false;
  disableSelect: boolean = false;
  selectedPeriods = [];
  disableSave: boolean = false;
  @ViewChild('select') select: NgSelectComponent;
  @ViewChild('selectInsp') selectInsp: NgSelectComponent;
  @ViewChild('selectStatus') selectStatus: NgSelectComponent;
  params: any;
  page: number = 1;
  page_count: number = 0;
  timeout: any = null;
  acadamicMajor: any;
  dateLabel = ' تاريخ الفحص ';
  datePlaceholder = 'يرجى تحديد تاريخ الفحص ';

  private onScroll = (event: any) => {
    if (this.select?.isOpen || this.selectInsp?.isOpen || this.selectStatus?.isOpen) {
      const isScrollingInScrollHost =
        (event.target.className as string)?.indexOf('ng-dropdown-panel-items') >
        -1;
      if (isScrollingInScrollHost) {
        return;
      }
      this.closeSelectPanel(this.select);
      this.closeSelectPanel(this.selectInsp);
      this.closeSelectPanel(this.selectStatus);
    }
  };
  alertmsg: boolean = false;
  errorMessage: string;
  @Output() errorMsg = new EventEmitter();
  orderType: any;
  periodLabel: string = ' فترة الفحص';
  showSpinner: boolean;
  orderData: any;

  closeSelectPanel(select) {
    if (select && select.isOpen) {
      select.close();
    }
  }
  searchInput: string;
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;
  @Select(OrderState.getServerError) serverError$: Observable<any>;
  private subscription!: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private inspectorService: InspectorsService,
    private InspectionService: InspectionService,
    private store: Store,
    private spinnerService: SpinnerLoaderService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.orderDetails$.subscribe((data) => {
      this.setOrderDetails(data);
    });

    this.selectedPeriods = this.orderData.order_periods;
    this.orderType = this.orderData.order_type;
    this.orderStatus = this.orderData.status;
    this.getInspectionStatus();
    this.getinspectionPeriods();
    this.getAcadamicMajor();

    window.addEventListener('scroll', this.onScroll, true);
    
    if (this.orderType == OrderType.Visit) {
      this.setOrderVisit();
    }

    if ([OrderStatus.Completed, OrderStatus.Delivered, OrderStatus.Under_Inspectoring].includes(this.orderStatus)) {
      this.disableSave = true;
    }
    this.subscription = this.spinnerService.loading$.subscribe((isLoading) => {
      this.showSpinner = isLoading;
    });
  }

  setOrderDetails(data) {
    this.orderData = data;
    this.patchValues();
    this.disableDate = this.orderData.is_inspection_later;
    this.disableSelect = this.orderData.is_inspection_later;
    this.updateFrom();
  }

  setOrderVisit() {
    this.dateLabel = 'تاريخ الزيارة';
    this.periodLabel = 'فترة الزيارة';
    this.datePlaceholder = 'يرجى تحديد تاريخ الزيارة';
    this.setRequiredValidatorVal('inspector_academic_major');
  }

  createForm() {
    this.orderMainDetailsFormGroup = this.formBuilder.group({
      reference_number: [null],
      order_inspector: [null],
      inspector_academic_major: [null],
      status: [null, Validators.required],
      order_date: [null, Validators.required],
      is_inspection_later: [false],
      order_periods: [null, Validators.required],
      notes: [null],
      change_delivered_order_to_complete_after_3_days: [null],
    });
  }

  createFormControls() {
    return this.orderMainDetailsFormGroup.controls;
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
    this.subscription.unsubscribe();
  }

  searchtext() {
    this.searchInput = this.selectInsp.searchTerm;
    this.params = { full_name: this.searchInput, ordering: 'full_name' };
    this.inspectors = [];
    clearTimeout(this.timeout);
    var $this = this;
    $this.timeout = setTimeout(() => {
      if ($this.searchInput != '') {
        $this.getInspectors(this.params);
      } else {
        this.params = { page: '1' };
        $this.getInspectors(this.params);
      }
    }, 600);
  }

  resetSearch() {
    this.inspectors = [];
    this.params = { page: '1' };
    this.getInspectors(this.params);
  }

  onScrollPagination() {
    if (this.page < this.page_count) {
      this.page++;
      this.params = { page: this.page };
      this.getInspectors(this.params);
    }
  }

  inspectorData(inspector) {
    if (inspector) {
      let inspectorData = this.inspectors.find((item) => item.id == inspector);
      this.orderMainDetailsFormGroup
        .get('inspector_academic_major')
        .setValue(inspectorData.academic_major);
    }
  }

  patchValues() {
    if (this.orderData?.order_details?.order_inspector?.pk) {
      let obj = {
        id: this.orderData.order_details.order_inspector.pk,
        full_name: this.orderData.order_details.order_inspector.full_name,
      };
      this.inspectors = [];
      this.inspectors.push(obj);
    }

    this.orderMainDetailsFormGroup.patchValue({
      reference_number: this.orderData.reference_number,
      inspector_academic_major: this.orderData.inspector_academic_major,
      order_inspector: this.orderData.order_details.order_inspector
        ? this.orderData.order_details.order_inspector.pk
        : null,
      status: this.orderData.status,
      order_date: moment(this.orderData.order_date),
      is_inspection_later: this.orderData.is_inspection_later,
      order_periods: this.orderData.order_periods,
      notes: this.orderData.notes,
      change_delivered_order_to_complete_after_3_days:
        this.orderData.change_delivered_order_to_complete_after_3_days,
    });
  }

  handleBackendError(error) {
    try {
    } catch { }
  }

  getInspection() {
    this.InspectionService.getInspectionPurpose().subscribe(
      (data) => {
        this.Inspection_purpose = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  getInspectors(page) {
    this.inspectorService.getAvailableInspectors(page).subscribe(
      (data) => {
        this.inspectors.push(...data.results);
        this.page_count = data.pages_count;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  getInspectionStatus() {
    this.InspectionService.getInspectionStatus().subscribe(
      (data) => {
        this.status = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  getinspectionPeriods() {
    this.InspectionService.getinspectionPeriods().subscribe(
      (data) => {
        this.inspectionPeriods = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  setOrderPeriods(event, enableSave) {
    this.orderMainDetailsFormGroup.controls.order_periods.patchValue([
      ...event,
    ]);
  }

  updateFrom() {
    if (this.disableDate) {
      this.changeFormControlValidators('order_date', null);
      this.selectedPeriods = [];
      this.changeFormControlValidators('order_periods', this.selectedPeriods);
    } else {
      this.setRequiredValidatorVal('order_date');
      this.setRequiredValidatorVal('order_date');
      let orderDateVal = this.orderData.order_date
        ? moment(this.orderData.order_date)
        : null;
      this.orderMainDetailsFormGroup.controls.order_date.setValue(orderDateVal);
      this.selectedPeriods = this.orderData.order_periods;
      this.setOrderPeriods(this.orderData.order_periods, false);
    }
  }

  changeFormControlValidators(formControl, newVal) {
    this.orderMainDetailsFormGroup.controls[formControl].setValue(newVal);
    this.orderMainDetailsFormGroup.controls[formControl].clearValidators();
    this.orderMainDetailsFormGroup.controls[
      formControl
    ].updateValueAndValidity();
  }

  setRequiredValidatorVal(formControl) {
    this.orderMainDetailsFormGroup.controls[formControl].setValidators(
      Validators.required
    );
    this.orderMainDetailsFormGroup.controls[
      formControl
    ].updateValueAndValidity();
  }

  toggleDisableDate(event) {
    this.disableDate = event.target.checked;
    this.disableSelect = event.target.checked;
    this.updateFrom();
  }

  save() {
    this.alertmsg = false;
    let orderUpdated = {};
    orderUpdated = this.orderMainDetailsFormGroup.value;
    if (this.orderMainDetailsFormGroup.get('order_date').value) {
      let formatedDate: any = moment(
        this.orderMainDetailsFormGroup.get('order_date').value
      );
      formatedDate = formatedDate.format('YYYY-MM-DD');
      orderUpdated['order_date'] = formatedDate;
    }
    if (
      this.orderMainDetailsFormGroup.status == 'INVALID' &&
      this.orderMainDetailsFormGroup.get('status').value != 8
    ) {
      this.alertmsg = true;
      this.errorMessage = 'يوجد خطأ في بعض البيانات';
      if (
        !this.orderMainDetailsFormGroup.controls.is_inspection_later.value &&
        this.orderMainDetailsFormGroup.controls.order_periods.value.length == 0
      ) {
        document.querySelector('#order_periods').classList.add('ng-invalid');
      }
      let fields = document.querySelectorAll(
        'ng-select.ng-invalid , mat-form-field.ng-invalid'
      );
      fields.forEach((field) => {
        field.parentElement.classList.add('invalid-field');
      });
      return;
    }
    this.disableSave = true;
    this.store.dispatch(
      new UpdateOrderDetails({ orderId: +this.orderData?.order_details.id, order: orderUpdated })
    ).subscribe(() => {
      this.disableSave = false;
      this.showConfirmationModal();
    });
    this.serverError$.subscribe(error => {
      if (error) {
        this.alertmsg = true;
        this.errorMessage = error;
      }
    });
  }

  getAcadamicMajor() {
    this.inspectorService.getAcadamicMajor().subscribe((data) => {
      this.acadamicMajor = data;
    });
  }

  showConfirmationModal() {
    this.errorMsg.emit({
      message: 'تم تحديث البيانات بنجاح.',
      imgSuccess: true,
    });
  }

  hideErrorMSG() {
    this.alertmsg = false;
  }
}
