@if (spinner) {
<app-spinner></app-spinner>
}
<div class="inner-page-header">
  <h3>ادارة المشاريع</h3>
</div>
@if (inspectionEvaluations) {
<div class="request-control">
  <div class="right-side">
    <div class="delete-icon-grid">
      <button class="btn btn-custom-delete" (click)="deleteSelectedRows()" [disabled]="disabledDelete">
        <img src="assets/images/svg/delete-icon-ins.svg" alt="" />
      </button>
    </div>
    @if (filterData) {
    <app-filter 
      [filterData]="filterData" 
      [filterDataInit]="filterDataInit" 
      [filterPage]="filterPage"
      (formValus)="filterFormValues($event)">
    </app-filter>
    }
  </div>
  <div class="left-side">
    <div class="new-element">
      <button class="btn btn-add" (click)="openEvaluation('add')">
        <img src="assets/images/svg/add-icon.svg" alt="" />
        إضافة تقييم
      </button>
    </div>
  </div>
</div>

<div class="saved-filters">
  <saved-filters></saved-filters>
</div>

<div class="request-table">
  <div class="table-responsive">
    <table id="project-table" aria-label="" class="table table-striped">
      <thead>
        <tr>
          <th id="" class="width-5">
            <label class="checkbox-container">
              <input type="checkbox" #selectAllElm (click)="selectAll($event.target)" />
              <span class="checkmark"></span>
            </label>
          </th>
          <th>المشروع</th>
          <th>الخدمة</th>
          <th>حالة تقييم البنود </th>
        </tr>
      </thead>
      <tbody>
        @for(evaluation of inspectionEvaluations | paginate: config; track evaluation; let i = $index) {
        <tr (click)="openEvaluation(evaluation.id)" class="table-row">
          <td class="width-5">
            <label class="checkbox-container" (click)="stopPropagation($event)">
              <input type="checkbox" #checkBoxList (click)="selectsSingleRow($event, evaluation.id, i)"
                [id]="i + '_' + evaluation.id" />
              <span class="checkmark"></span>
            </label>
          </td>
          <td>{{evaluation?.contract?.name}}</td>
          <td>{{evaluation?.dynamic_service?.name}}</td>
          <td>
            <button class="btn" [ngClass]="{
                  'not-active': !evaluation.is_active,
                  active: evaluation.is_active
                }">
              @if (!evaluation.is_active) {
              <span> غير </span>
              }
              مفعل
            </button>
          </td>
        </tr>
        }
        @if (inspectionEvaluations.length === 0) {
        <tr>
          <td colspan="5" class="text-center">لا توجد بيانات</td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>
@if (inspectionEvaluations && inspectionEvaluations.length > 0) {
<ayen-pagination 
  (pageData)="pageChanged($event)" 
  (pageSize)="pageSizeChanged($event)" 
  [totalCount]="config.totalItems"
  [pageTitle]="pageTitle">
</ayen-pagination>
}
}
