import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ColumnsUtilService } from 'src/app/core/services/uitls/columns-util/columns-util.service';
import { ProjectsService } from 'src/app/features/projects/services/projects.service';

@Component({
  selector: 'dynamic-columns',
  templateUrl: './dynamic-columns.component.html',
  styleUrl: './dynamic-columns.component.scss'
})
export class DynamicColumnsComponent {

  columns = [];
  customizeColData = []

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(private columnsUtil: ColumnsUtilService, private projectServices: ProjectsService) {}

  menuOpened() {
    this.getColumnData();
  }

  getColumnData() {
    this.columnsUtil.currentColState.subscribe( data => {
      this.columns = [...data];
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  toggleVisibleColumn(index, columnInstance) {
    this.columns[index].visible = !this.columns[index].visible;
    this.columnsUtil.applyColumnsData(this.columns);
    this.projectServices.customizeColumnGrid(columnInstance).subscribe(data =>{});
  }
}
