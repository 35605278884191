import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicServices } from 'src/app/features/ayen-services/services/dynamic-services/dynamic-services.service';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { ChangeCategoryComponent } from 'src/app/features/ayen-services/components/change-category/change-category.component';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { Select, Store } from '@ngxs/store';
import {
  AddService,
  ChangeCategoriesRatio,
  ChangeTermCategory,
  GetAdditionsList,
  GetServiceById,
  HandleError,
  RedistrbuteRatios,
  UpdateCategory,
  UpdateService,
} from '../../state/service-action';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { NewServicesState } from '../../state/ayen-service.state';
import { AddCategoriesComponent } from '../../components/add-categories/add-categories.component';
import { ServiceCredentialsComponent } from '../../components/service-credentials/service-credentials.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { PromptBoxComponent } from 'src/app/shared/components/prompt-box/prompt-box.component';
import { SettingServicesService } from '../../services/setting-services/setting-services.service';
import { CompletionStatusComponent } from 'src/app/shared/components/completion-status/completion-status.component';

@Component({
  selector: 'app-new-service',
  templateUrl: './new-service.component.html',
  styleUrls: ['./new-service.component.scss'],
})
export class NewServiceComponent implements OnInit, OnDestroy {
  addServiceFormGroup: UntypedFormGroup;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  addCategoryRef: MatDialogRef<AddCategoriesComponent>;
  changeCategoryRef: MatDialogRef<ChangeCategoryComponent>;
  confirmationDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  credentialsDialogRef: MatDialogRef<ServiceCredentialsComponent>;
  statusRef: MatDialogRef<CompletionStatusComponent>;

  credentials = {
    academic_major: [],
    years_of_experience: [],
    certificates: [],
    qualification_choice: [],
    category: [],
    inspector_type: [],
    expertise_areas: [],
    special_qualification_tags: []
  };
  confirmMsg: string = '';
  successImg: boolean = true;
  serviceId: any;
  serviceDetails: any;
  addStatus: boolean;
  alertmsg: boolean;
  message: string = '';
  isActive: boolean = true;
  allow_inspector_to_skip_terms: boolean = false;
  is_droplist: boolean = false;
  backUrl: string = '/pages/services/list';
  choicesList = [];
  statusCustomChoices = [];
  buildingDetails = [];
  initBuildingDetails: string[] = [];
  initAllowanceFields = [];
  allowanceFieldsValues = [];
  @Select(NewServicesState.getServiceById) serviceById$: Observable<any>;
  @Select(NewServicesState.serverError) serverError$: Observable<string>;
  @Select(NewServicesState.getAdditionList) AdditionsList$: Observable<any>;
  serviceByIdSubscribtion: Subscription;
  errorMsg: boolean = false;
  selectedAccordion: number;
  allowanceFields: any[];
  @ViewChild('custonCompleteDisplay') custonCompleteDisplay: NgSelectComponent;
  @ViewChild('showTerms') showTerms: NgSelectComponent;

  private onScroll = (event: any) => {
    if (this.custonCompleteDisplay?.isOpen || this.showTerms?.isOpen ) {
      const isScrollingInScrollHost =
        (event.target.className as string).indexOf('ng-dropdown-panel-items') >
        -1;
      if (isScrollingInScrollHost) {
        return;
      }
      this.custonCompleteDisplay.close();
      this.showTerms.close();
    }
  };

  additionList: any[] = [];
  config: any;
  payload: { page: number; query: string; page_size: number };
  currentItemsPerPage: number;
  page: number = 1;
  totalRecords: any = 0;
  dynamicServicesItems = new BehaviorSubject({});
  additionData = this.dynamicServicesItems.asObservable();
  initAdditions: any[] = [];
  status_payload: { page: any; query: string; page_size: any; };
  status_config: any;
  mainTitle: string;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private dynamicService: DynamicServices,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private settingServices: SettingServicesService

  ) {
    this.config = {
      id: 'additionList',
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
      pageCount: 0
    };
  }

  ngOnDestroy(): void {
    this.serviceByIdSubscribtion?.unsubscribe();
    window.removeEventListener('scroll', this.onScroll, true);
  }

  ngOnInit(): void {
    this.createForm();
    this.getDynamicServicesChoices();
    this.getStatusCustomDisplay();
    this.getBuildingData();
    this.setPaginationConfig('status_config');

    // this.getDynamicAdditions();
    // this.createAllowanceFieldsList();
    if (this.router.url.includes('new')) {
      this.mainTitle = 'إضافة';
      this.addStatus = true;
      this.confirmMsg = 'تم الحفظ بنجاح';
    } else if (this.router.url.includes('edit')) {
      this.mainTitle = 'تعديل';
      this.addStatus = false;
      this.serviceId = this.activatedRoute.snapshot.params.id;
      this.getServiceById();
      this.confirmMsg = 'تم الحفظ بنجاح';
    }
    this.serverError$.subscribe((data) => {
      if (data.length !== 0) {
        this.successImg = false;
        this.confirmMsg = data;
        this.showAlertBox();
      }
    });
    window.addEventListener('scroll', this.onScroll, true);
  }

  createForm() {
    this.addServiceFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      explantion_txt: [''],
      is_active: [true],
      show_terms: [null, Validators.required],
      completion_status: [null, Validators.required],
      categories: this.formBuilder.array([]),
      building_data_to_show: [null],
      service_credentials: [this.credentials],
    });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      ratio: [0],
      repairing_cost: [0],
      id: [null],
    });
  }
  setPaginationConfig(config) {
    this[config] = {
      id: config,
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
      pageCount: 0,
    };
  }
  createCategory(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      id: [null],
      ratio: [0],
      terms: this.formBuilder.array([]),
    });
  }

  categories(): FormArray {
    return this.addServiceFormGroup.get('categories') as FormArray;
  }

  terms(categoryIndex): FormArray {
    return this.categories().at(categoryIndex).get('terms') as FormArray;
  }

  addCategory(): void {
    this.categories().push(this.createCategory());
  }

  addItem(categoryIndex): void {
    this.terms(categoryIndex).push(this.createItem());
  }

  deleteItem(categoryIndex, index) {
    this.terms(categoryIndex).removeAt(index);
  }

  isActiveValueChanged(event) {
    this.addServiceFormGroup.get(event.labelEn).setValue(event.value);
  }

  saveCategory(categoryIndex) {
    this.store.dispatch(new HandleError("")).subscribe(()=>{})
    const category =
      this.addServiceFormGroup.get('categories').value[categoryIndex];
    this.store
      .dispatch(new UpdateCategory(category.id, category, categoryIndex))
      .subscribe(
        (data) => {
          this.successImg = true;
          this.errorMsg = false;
          this.confirmMsg = 'تم الحفظ بنجاح'
          this.showAlertBox();
        },
        (error) => {
          try {
            this.successImg = false;
            this.errorMsg = true;
            this.serverError$.subscribe((data) => {
              this.confirmMsg = data;
              this.message = this.confirmMsg;
            });
            this.showAlertBox();
          } catch (error) {}
        }
      );
  }

  changeCategory(item, categoryIndex) {
    this.changeCategoryRef = this.dialog.open(ChangeCategoryComponent, {
      data: {
        serviceId: this.serviceId,
        termId: item?.id?.value,
      },
    });
    this.changeCategoryRef.afterClosed().subscribe((results) => {
      if (results?.category) {
        this.store
          .dispatch(
            new ChangeTermCategory(results.termId, {
              category: results.category,
            })
          )
          .subscribe((data) => {
            this.store.dispatch(new GetServiceById(this.serviceId));
          });
      }
    });
  }

  save() {
    if (this.addServiceFormGroup.valid) {
      this.alertmsg = false;
      const serviceObj = {
        name: this.addServiceFormGroup.get('name').value,
        explantion_txt: this.addServiceFormGroup.get('explantion_txt').value,
        is_active: this.addServiceFormGroup.get('is_active').value,
        show_terms: this.addServiceFormGroup.get('show_terms').value,
        completion_status: this.addServiceFormGroup.get(
          'completion_status'
        ).value,
        building_data_to_show: this.addServiceFormGroup.get(
          'building_data_to_show'
        ).value,
        service_credentials: this.addServiceFormGroup.get('service_credentials')
          .value,
      };
      if (!this.addStatus) {
        this.store
          .dispatch(new UpdateService(this.serviceId, serviceObj))
          .subscribe(
            (data) => {
              this.successImg = true;
              this.showAlertBox();
            },
            (error) => {
              try {
                this.successImg = false;
                this.confirmMsg = error.error;
                this.showAlertBox();
              } catch (error) {}
            }
          );
      } else {
        this.store.dispatch(new AddService(serviceObj)).subscribe(
          (data) => {
            let serviceId = data.NewServicesState.serviceById.id;
            this.successImg = true;
            this.showAlertBox();
            this.router.navigate([`pages/services/edit/${serviceId}`], {
              replaceUrl: true,
            });
          },
          (error) => {
            try {
              this.successImg = false;
              this.confirmMsg = error.error;
              this.showAlertBox();
            } catch (error) {}
          }
        );
      }
    } else {
      this.alertmsg = true;
      this.message = 'برجاء تعبئة  جميع الخانات ';
    }
  }

  getServiceById() {
    this.serviceByIdSubscribtion = this.serviceById$.subscribe(
      (data) => {
        if (data?.id && data?.id == +this.serviceId) {
          let categoriesArr = <FormArray>(
            this.addServiceFormGroup.controls['categories']
          );
          categoriesArr.clear();
          this.serviceDetails = data;
          this.initBuildingDetails = this.serviceDetails.building_data_to_show;
          this.initAdditions = this.serviceDetails.additions;
          this.isActive = this.serviceDetails.is_active;
          this.allow_inspector_to_skip_terms =
            this.serviceDetails.allow_inspector_to_skip_terms;
          this.is_droplist = this.serviceDetails.is_droplist;
          this.addServiceFormGroup.patchValue({
            name: this.serviceDetails.name,
            explantion_txt: this.serviceDetails.explantion_txt,
            is_active: this.serviceDetails.is_active,
            allow_inspector_to_skip_terms:
              this.serviceDetails.allow_inspector_to_skip_terms,
            is_droplist: this.serviceDetails.is_droplist,
            show_terms: this.serviceDetails.show_terms,
            completion_status:
              this.serviceDetails.completion_status,
            building_data_to_show: this.serviceDetails.building_data_to_show,
            additions: this.serviceDetails.additions

          });
          this.serviceDetails?.categories?.forEach((element, index) => {
            this.addCategory();
            this.categories().at(index).get('name').setValue(element.name);
            this.categories().at(index).get('id').setValue(element.id);
            this.categories().at(index).get('ratio').setValue(element.ratio);
            this.pushTermsData(index);
          });
        } else {
          this.store.dispatch(new GetServiceById(this.serviceId));
        }
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  pushTermsData(categoryIndex) {
    if (this.serviceDetails?.categories[categoryIndex].terms.length == 0) {
      this.addItem(categoryIndex);
    } else {
      this.serviceDetails?.categories[categoryIndex].terms.forEach(
        (element, index) => {
          this.addItem(categoryIndex);
          this.terms(categoryIndex).at(index).get('id').setValue(element.id);
          this.terms(categoryIndex)
            .at(index)
            .get('name')
            .setValue(element.name);
          this.terms(categoryIndex)
            .at(index)
            .get('description')
            .setValue(element.description);
          this.terms(categoryIndex)
            .at(index)
            .get('ratio')
            .setValue(element.ratio);
        }
      );
    }
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.successImg,
        message: this.confirmMsg,
        button: 'تم',
      },
    });
  }

  updateCategories() {
    this.addCategoryRef = this.dialog.open(AddCategoriesComponent, {
      data: this.serviceDetails?.categories,
    });
    this.addCategoryRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          new ChangeCategoriesRatio(this.serviceId, result.categories)
        );
      }
    });
  }

  closeError() {
    this.alertmsg = false;
  }

  goBack() {
    this.router.navigateByUrl('pages/services/list');
  }

  goToSettings() {
    this.router.navigateByUrl(`pages/services/settings/${this.serviceId}`);
  }

  getDynamicServicesChoices() {
    this.dynamicService.getDynamicServicesChoices().subscribe(
      (data) => {
        data['show_choices'].map((item) => {
          this.choicesList.push({ id: item[0], name: item[1] });
        });
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  getBuildingData() {
    this.dynamicService.getBuildingData().subscribe((data) => {
      this.buildingDetails = data;
    });
  }

  setBuildingDetails(event) {
    this.addServiceFormGroup.controls.building_data_to_show.patchValue([
      ...event,
    ]);
  }

  getStatusCustomDisplay() {
    this.status_payload = {
      page: this.status_config?.currentPage,
      query: '',
      page_size: this.status_config?.itemsPerPage,
    };
    this.dynamicService.getStatusCustomDisplay().subscribe((data) => {
      this.statusCustomChoices = data['results'];
      this.status_config['totalItems'] = data['count'];
    });
  }

  openServiceCredentials() {
    this.credentialsDialogRef = this.dialog.open(ServiceCredentialsComponent, {
      data: this.serviceDetails.service_credentials,
    });
    this.credentialsDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addServiceFormGroup.get('service_credentials').setValue(result);
        this.save();
      }
    });
  }

  redistrebuteRatios() {
    this.confirmationDialogRef = this.dialog.open(ConfirmationBoxComponent, {
      data: {
        message: 'هل انت متأكد من اعادة توزيع النسب اتوماتيكيا؟',
        confirmBtn: 'موافق',
      },
    });
    this.confirmationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new RedistrbuteRatios(this.serviceId));
      }
    });
  }

  addCompletionStatus() {
    this.statusRef = this.dialog.open(CompletionStatusComponent, {
      data: {
        label: 'اسم نوع التقييم',
        placeholder: 'برجاء ادخال نوع التقييم ',
        textValue: '',
      },
    });
    this.statusRef.afterClosed().subscribe((results) => {
      console.log(results)
      if(results?.choices) {
        this.settingServices.addStatus(results).subscribe(data =>{
          this.status_payload.page =  1;
          this.status_config.currentPage = this.status_payload.page;
          this.getStatusCustomDisplay()
        })
      }
    });
  }
}
