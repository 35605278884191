import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { GetOrderMsg, GetPropertylocation, OrderDetails, PostPropertyLocation, UpdateOrderDetails } from './order-action';
import { AdminOrdersService } from '../services/orders/admin-orders.service';
import { OrderDetailsService } from '../services/order-details/order-details.service';

@State<any>({
  name: 'OrderState',
  defaults: {
    orderDetails: {},
    orderMsg: [],
  },
})
@Injectable()
export class OrderState {
  constructor(private adminOrdersService: AdminOrdersService,
    private orderDetailsService: OrderDetailsService,
    ) {}

  @Selector()
  static getOrderDetails(state: any): any {
    return state.orderDetails;
  }
  @Selector()
  static getOrderMsg(state: any): any {
    return state.orderMsg;
  }
  @Selector()
  static getServerError(state: any) {
    return state.serverError;
  }

  @Selector()
  static getPropertyLocation(state: any) {
    return state.propertyLocation;
  }

  @Action(OrderDetails)
  orderDetails(ctx: StateContext<any>, action: OrderDetails) {
    return this.adminOrdersService.getAdminOrder(action.payload.orderId).pipe(
      tap((res) => {
        ctx.patchState({
          orderDetails: { ...res },
          serverError: undefined,
        });
      }),
      catchError((err) => {
        return this.getServerError(ctx, err);
      })
    );
  }

  @Action(GetOrderMsg)
  getOrderMsg(ctx: StateContext<any>, action: GetOrderMsg) {
    return this.adminOrdersService.getMessagesLog(action.payload.orderId).pipe(
      tap((res) => {
        ctx.patchState({
          orderMsg: [...res],
          serverError: undefined,
        });
      }),
      catchError((err) => {
        return this.getServerError(ctx, err);
      })
    );
  }

  @Action(UpdateOrderDetails)
  updateOrderDetails(ctx: StateContext<any>, action: UpdateOrderDetails) {
    return this.adminOrdersService
      .updateOrder(action.payload.orderId, action.payload.order)
      .pipe(
        tap((res) => {
          ctx.patchState({
            orderDetails: res,
            serverError: undefined,
          });
        }),
        catchError((err) => {
          return this.getServerError(ctx, err);
        })
      );
  }

  @Action(PostPropertyLocation)
  postPropertyLocation(ctx: StateContext<any>, action: PostPropertyLocation) {
    return this.orderDetailsService.postPropertyLocation(action.payload.orderId, action.payload.propertyLocation)
    .pipe( tap( res => {
      ctx.patchState({
        propertyLocation: res
      })
    }),
    catchError((err) => {
      return this.getServerError(ctx, err);
    }))
  }

  @Action(GetPropertylocation)
  getPropertylocation(ctx: StateContext<any>, action: GetPropertylocation) {
    return this.orderDetailsService.getPropertyLocation(action.payload.orderId)
    .pipe( tap( res => {
      ctx.patchState({
        propertyLocation: res
      })
    }),
    catchError((err) => {
      return this.getServerError(ctx, err);
    }))
  }

  getServerError(ctx, error) {
    return ctx.patchState({
      serverError: error.error ? Object.values(error?.error)[0] : undefined,
    });
  }
}
