<table id="order-table" aria-label="" class="table table-striped">
  <thead>
    <tr>
      <th>
        <label class="checkbox-container">
          <input type="checkbox" id="custom_check_all"
            (click)="selectAll($event.target)"/>
            <span class="checkmark"></span>
          </label>
          <span #element class="checkbox-label"
            (click)="toggleSorting('reference_number', element)">
            رقم الطلب
            <img [src]="imgDefault" alt="" />
          </span>
        </th>
        @for (item of tableHeaderData | slice: 1; track item; let i = $index) {
          <th
            #elements (click)="toggleSorting(item.value, elements)">
            {{ item.display_name }}
            @if (item.sortable) {
              <img [src]="imgDefault" alt="" />
            }
          </th>
        }
      </tr>
    </thead>
    <tbody>
      <ng-container>
        @for (row of dataTable | paginate: paginationConfig ; track row; let i = $index) {
          <tr (click)="openOrder(row['id'])">
            <td>
              <label class="checkbox-container" (click)="stopPropagation($event)">
                <input type="checkbox" (click)="deleteSelectRow($event, row['id'], i)"
                  [id]="i + '_' + row['id']"/>
                  <span class="checkmark"></span>
                </label>
                <span class="checkbox-label">
                  <a href="/pages/orders/{{ row['id'] }}">
                    {{ row["reference_number"] || "-" }}
                    @if (row['is_locked']) {
                      <img
                        alt="lock-icon"
                        src="assets/images/svg/lock-icon.svg"
                        width="12"
                        class="mr-1"
                      />
                    }
                  </a>
                  @if (row['is_test']) {
                    <span class="test-label">تجريبي</span>
                  }
                  @if (row['parent_id']) {
                    <span class="test-label parent"
                      (click)="stopPropagation($event); openOrder(row['parent_id'], true)">
                      الطلب الرئيسي
                    </span>
                  }
                </span>
              </td>
              @for (header of tableHeaderData | slice: 1; track header) {
                <td>
                  @if (isObject(row[header.value])) {
                    @if (header.value !== 'order_status_object') {
                      @if (header.value !== 'order_inspector') {
                        <span>{{ row[header.value]?.name || row[header.value]?.full_name || "-" }}</span>
                      } @else {
                        @if (row[header.value]?.name || row[header.value]?.full_name) {
                          <a (click)="stopPropagation($event)"
                            [routerLink]="['/pages/inspectors/inspector-profile/', row[header.value]?.pk]">
                            {{ row[header.value]?.name || row[header.value]?.full_name}}
                          </a>
                        } @else {
                          <span> - </span>
                        }
                      }
                      <ng-template #inspector>
                        @if (row[header.value]?.name || row[header.value]?.full_name) {
                          <a (click)="stopPropagation($event)"
                            [routerLink]="['/pages/inspectors/inspector-profile/', row[header.value]?.pk]">
                            {{ row[header.value]?.name || row[header.value]?.full_name}}
                          </a>
                        } @else {
                          <span> - </span>
                        }
                      </ng-template>
                    } @else {
                      <button class="btn-checked {{ getStatusClass(row[header.value].id) }}">
                        {{ row[header.value].name }}
                      </button>
                    }
                  } @else if (isBoolean(row[header.value])) {
                    @if(row[header.value]) {
                      <button class="btn-checked completed">مقبول</button>
                    } @else {
                      <button class="btn-checked canceled">مرفوض</button>
                    }
                  } @else {
                    @if (isURL(row[header.value])) {
                      <img class="d-block m-auto"
                          (click)="stopPropagation($event); openNewTab(row[header.value])" 
                          src="../../../../assets/images/svg/new-tab-img.svg" alt=""
                      >
                    } @else {
                      {{ row[header.value] || "-" }}
                    }
                  }
                </td>
              }
            </tr>
          }
        </ng-container>
      </tbody>
    </table>
