import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionEvaluationsService extends AYENServiceBase {

  constructor(private http: HttpClient) { 
    super();
  }

  createInspectionEvaluation(data): Observable<any> {
    return this.http.post(this.baseURL + `/admin/contracts/evaluation-inspection/`, data);
  }

  getProjectServices(contractID): Observable<any> {
    return this.http.get<any>(this.baseURL + `/admin/contracts/${contractID}/dynamic-services`);
  }

  getInspectionEvaluations(params): Observable<any> {
    return this.http.get<any>(this.baseURL + `/admin/contracts/evaluation-inspection/`, {params});
  }

  getInspectionEvaluationByID(id): Observable<any> {
    return this.http.get<any>(this.baseURL + `/admin/contracts/evaluation-inspection/${id}`);
  }

  putInspectionEvaluation(id, data): Observable<any> {
    return this.http.put<any>(this.baseURL + `/admin/contracts/evaluation-inspection/${id}`, data);
  }

  getInspectionEvaluationTerms(inspectionEvaluationID): Observable<any> {
    return this.http.get<any>(this.baseURL + `/admin/contracts/evaluation-inspection-terms/${inspectionEvaluationID}/`);
  }

  getCriteria() : Observable<any> {
    return this.http.get<any>(this.baseURL + `/admin/contracts/criteria`);
  }

  updateInspectionEvaluationTerm(termID, data): Observable<any> {
    return this.http.put<any>(this.baseURL + `/admin/contracts/evaluation-inspection-terms/${termID}/criteria`, data);
  }

  deleteEvaluations(evaluationIds): Observable<any> {
    return this.http.request( 'DELETE',
      this.baseURL + `/admin/contracts/evaluation-inspection/`,
      {
        body: evaluationIds,
      }
    );
  }
}
